.privacy{
    position: relative;
    padding: 40px 5px;
    top: 65px;
    max-width: 850px;
    margin-bottom: 120px;
}

.paragraph{
    margin-bottom: 19px;
    line-height: 1.7em;
}