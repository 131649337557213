* {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
}

body {
  background-color: #f6f6f6;
  color: #515070;
}

.gradient-background {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(34, 34, 34, 1) 100%
  );
  z-index: -3;
}

/* background colors */

.dark-back {
  background-color: #ff8e6e;
}

.light-back {
  background-color: #ffbb91;
}

/* global text styling */

h1 {
  font-size: 4.5rem;
  font-family: 'Titillium Web', sans-serif;
}

h2 {
  font-size: 3rem;
  font-family: 'Titillium Web', sans-serif;
}

h3 {
  font-size: 1.8rem;
  font-family: 'Titillium Web', sans-serif;
}

h4 {
  font-size: 1.5rem;
  font-family: 'Titillium Web', sans-serif;
}

.golden-text {
  color: rgb(195, 167, 105);
}
.darker-text {
  color: #222222;
}

.dark-text {
  color: #585858;
}

.grey-text {
  color: #a3a3a3;
}

.light-text {
  color: rgb(241, 241, 241);
}

.bold {
  font-weight: bold;
}

.small-text {
  font-size: 1.2rem;
  line-height: 1.9rem;
  margin: 25px 0;
  font-weight: 400;
}

/* buttons */

.button {
  padding: 15px 0;
  background-color: #515070;
  color: white;
  font-weight: 700;
  font-size: 1.15rem;
  cursor: pointer;
  transition: all 0.3s ease-out;
  text-decoration: none;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 250px;
  border-radius: 7px;
  margin: 20px 0;
  -webkit-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 7px 18px 0px rgba(0, 0, 0, 0.21);
}

.buttonIcon {
  position: absolute;
  right: 40px;
  top: 50%;
  transform: translateY(-50%) rotate(150deg);
  font-size: 1.15rem;
  transition: all 0.3s ease-out;
  color: #515070;
}

.button:hover {
  background-color: #070542;
  color: white;
  font-size: 1.4rem;
}

.button:hover .buttonIcon {
  right: 20px;
  transform: translateY(-50%) rotate(0deg);
  color: white;
}

/* app buttons */

.app-button {
  position: relative;
  top: 0;
  transition: all 0.15s ease-out;
  width: 190px;
  cursor: pointer;
}

.app-button:hover {
  top: -3px;
  -webkit-box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.671);
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.15);
}

.apple {
  margin-left: 10px;
}

/* phone */

.only-phone {
  width: 220px;
  max-width: 60%;
  margin: 0;
}

.global-link {
  text-decoration: none;
  padding: 0;
  margin: 0;
}

/* global centering */

.center-all {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}

.text-center {
  text-align: center;
}

.center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

/* global padding */

.padding20 {
  padding: 20px 0;
}

.padding50 {
  padding: 50px 0;
}

/* grid */

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.grid3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

/* global media querys */

@media (max-width: 908px) {
  .app-button {
    max-width: 47%;
  }
  .button-box {
    width: fit-content;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 680px) {
  .grid3 {
    grid-template-columns: 1fr;
  }
}
