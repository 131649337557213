/* header component */

.header {
  display: flex;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  align-items: center;
  background-color: #f6f6f6;
  height: 65px;
  color: #515070;
  -webkit-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.21);
  -moz-box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.21);
  box-shadow: 0px 7px 18px 0px rgba(0,0,0,0.21);
  z-index: 2;
  position: fixed;
  width: 100%;

}

.logo-word {
  height: 22px;
  cursor: pointer;
  margin-left: 35px;
}
.navbar {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 10px;
  font-size: 1.2rem;
  transition: all 0.3s ease-out;
}

.nav-element {
  cursor: pointer;
  padding: 7px;
  transition: all 0.3s ease-out;
  text-decoration: none;
  color: #515070;
  position: relative;
}
.nav-element:hover {
  border-radius: 5px;
  color: rgb(195, 167, 105);
}

.fa-bars {
  position: absolute;
  right: 0;
  margin-right: 20px;
  font-size: 1.7rem;
  top: 50%;
  transform: translateY(-50%);
  color: rgb(195, 167, 105);
}

.nav-bar-icon {
  display: none !important;
}

@media (min-width: 900px) {
  .navbar {
    margin-right: 20px;
  }
}

@media (max-width: 900px) {
  .header {
    display: block;
    height: 70px;
    position: fixed;
      }

  .logo-word {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .navbar {
    display: flex;
    position: absolute;
    top: 70px;
    flex-direction: column;
    left: -150%;
    width: 100%;
    transition: left 0.5s ease-out, background-color 0.5s ease-out,
      color 1s ease-out;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }

  .navbar.drop {
    left: 0;
    top: 70px;
    background-color: #f6f6f6;
    color: white;
    z-index: 1;
    transition: left 0.5s ease-out, background-color 0.5s ease-out,
      color 0.6s ease-out;
  }

  .fa-bars {
    display: initial;
  }

  .nav-bar-icon {
    display: initial !important;
  }

  .nav-element {
    height: 45px;
  }
}
