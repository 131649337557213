.first-card{
    position: relative;
    padding: 40px 5px;
    top: 65px;
}

.blob1{
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    max-width: 90%;
}
.dataScience{
    width: 250px;
    margin: 50px 0;
    display: block;
    position: relative;
    transform: translateX(-50%);
    left: 50%;

}
.description{
    width: 800px;
    max-width: 90%;
}

.ot-form-wrapper {
    max-width: 750px;
    height: 800px;
    border: 1px solid #C0C2C7;
    margin: auto;
}
.ot-form-wrapper iframe {
    width: 100%;
    height: 100%;
    border: none;
}