.second-card{
    position: relative;
    padding: 40px 5px;
}

.overBox{
    max-width: 1100px;
}

.blob2{
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    max-width: 90%;
    transform: rotate(180deg);
}
.dataScience{
    width: 250px;
    margin: 50px 0;
}

.description{
    width: 800px;
    max-width: 90%;
}

.logoTitle{
    width: 250px;
    margin: 70px 0;
}

.content-card{
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background: rgb(81,80,112);
    background: linear-gradient(149deg, rgb(163, 161, 206) 0%, rgba(96,95,125,1) 53%, rgba(65,64,79,1) 100%);    color: white; */
    background: rgb(255,180,159);
    background: linear-gradient(149deg, rgba(255,180,159,1) 0%, rgba(255,142,110,1) 50%, rgba(201,70,70,1) 100%);color: white;
    width: 200px;
    margin: 30px 0;
    border-radius: 100px;
    -webkit-box-shadow: 0px 12px 40px 6px rgba(0,0,0,0.17);
    -moz-box-shadow: 0px 12px 40px 6px rgba(0,0,0,0.17);
    box-shadow: 0px 12px 40px 6px rgba(0,0,0,0.17);}

    .circleIcon{
font-size: 3rem;
color: #515070;

    }