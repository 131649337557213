.footer{
    position: relative;
    height: 250px;
    backgroundColor: #f6f6f6;
    -webkit-box-shadow: 0px -32px 40px -20px rgba(0,0,0,0.17);
-moz-box-shadow: 0px -32px 40px -20px rgba(0,0,0,0.17);
box-shadow: 0px -32px 40px -20px rgba(0,0,0,0.07);
display: flex;
flex-direction: column;
justify-content: space-around;
align-items: center;
padding: 20px 0;
z-index: 2;
}
.footerLogo{
    width: 350px;
}

.wallLink{
    text-decoration: none;
    color: rgb(148, 158, 12);
transition: all 0.22s ease-in-out;
font-size: 1.0rem;
}

.wallLink:hover{
    text-decoration: none;
    color: rgb(224, 224, 98);
    font-size: 1.02rem;
}